import React, { useState } from "react";
import "../Footer/Footer.css";
import emailjs from "emailjs-com";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Importar funciones de Storage
import { storage } from "../firebaseConfig";

const Footer = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [city, setCity] = useState("");
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null); // Almacena el archivo seleccionado
    const [sent, setSent] = useState(false);

    const handleModalToggle = () => {
        if (isModalOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setModalOpen(false);
                setIsClosing(false);
            }, 300);
        } else {
            setModalOpen(true);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 1 * 1024 * 1024) { // Tamaño máximo: 1MB
                alert("El archivo es demasiado grande. Máximo permitido: 1MB.");
                return;
            }
            setFile(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            alert("Por favor, adjunta tu CV antes de enviar el formulario.");
            return;
        }

        try {
            // Subir archivo a Firebase Storage
            const fileRef = ref(storage, `CV_Empleados/${file.name}`);
            await uploadBytes(fileRef, file);

            // Obtener URL de descarga del archivo
            const downloadURL = await getDownloadURL(fileRef);

            // Preparar datos para EmailJS
            const templateParams = {
                from_name: name,
                to_name: "J&J LTDA",
                message: `Edad: ${age}\nCiudad: ${city}\nMensaje:\n${message}\n\nEnlace al archivo: ${downloadURL}`,
                reply_to: "info@jyltda.com",
            };

            emailjs
                .send(
                    "service_hjtczth", // Cambia por tu Service ID
                    "template_hqqkut8", // Cambia por tu Template ID
                    templateParams,
                    "OtVefZRXKM3KBu2ye" // Cambia por tu Public Key
                )
                .then(
                    (response) => {
                        console.log("Mensaje enviado con éxito!", response.status, response.text);
                        setSent(true);

                        // Limpiar los campos del formulario
                        setName("");
                        setAge("");
                        setCity("");
                        setMessage("");
                        setFile(null);
                    },
                    (err) => {
                        console.error("Error al enviar el mensaje", err);
                    }
                );
        } catch (error) {
            console.error("Error al subir el archivo a Firebase Storage", error);
        }
    };

    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <img src={"img/10.webp"} alt="Person" className="person-image" />
                </div>
                <div className="footer-section about">
                    <h2 className="title-footer">Digital Solutions SpA.</h2>
                    <p className="description-footer">Sociedad multidisciplinaria en búsqueda de profesionales y especialistas.</p>
                    <button className="apply-button" onClick={handleModalToggle}>
                        Postula Aquí
                    </button>
                </div>
                <div className="footer-section links footer-empresa">
                    <h3>EMPRESA</h3>
                    <ul>
                        <li><a href="/about">Acerca de</a></li>
                        <li><a href="/performance">Desempeño</a></li>
                        <li><a href="/teams">Equipos</a></li>
                        <li><a href="/leaders">Líderes</a></li>
                    </ul>
                </div>
                <div className="footer-section links">
                    <h3>AYUDA</h3>
                    <ul>
                        <li><a href="/support">Soporte Técnico</a></li>
                        <li><a href="/payments">Problema con Pagos</a></li>
                        <li><a href="/terms">Términos y Condiciones</a></li>
                        <li><a href="/privacy">Políticas de Privacidad</a></li>
                    </ul>
                </div>
                <div className="footer-section links">
                    <h3>SOCIAL</h3>
                    <ul>
                        <li><a href="https://www.linkedin.com/in/juan-pozo-aravena-92b6b823a/" target="_blank" rel="noreferrer">LinkedIn</a></li>
                        <li><a href="https://www.facebook.com/share/LLPn2voXKTDkwxoL/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">Facebook</a></li>
                        <li><a href="https://www.instagram.com/digitalsolutionschile?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noreferrer">Instagram</a></li>
                        <li><a href="https://www.tiktok.com/@digitalsolutionschile?_t=8qnunH9aaKD&_r=1" target="_blank" rel="noreferrer">TikTok</a></li>
                    </ul>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
                    <div className={`modal ${isClosing ? "fade-out" : ""}`}>
                        <h3 className="modal-title">Trabaja con Nosotros</h3>
                        <form className="modal-form" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Nombre Completo"
                                className="modal-input"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <div className="modal-input-group">
                                <input
                                    type="number"
                                    placeholder="Edad"
                                    className="modal-input"
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Ciudad"
                                    className="modal-input"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                            </div>
                            <label className="modal-file-label-cv">Adjunta tu CV:</label>
                            <input
                                type="file"
                                className="modal-input"
                                onChange={handleFileChange}
                                required
                                accept=".pdf,.docx,.doc"
                            />
                            <textarea
                                placeholder="Coméntanos tus áreas de interés y habilidades..."
                                className="modal-textarea"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                maxLength="500"
                                required
                            ></textarea>
                            <div className="char-counter">{message.length}/500</div>
                            <button type="submit" className="modal-submit-button">
                                Enviar
                            </button>
                            <button
                                type="button"
                                className="modal-close-button"
                                onClick={handleModalToggle}
                            >
                                Cerrar
                            </button>
                        </form>
                        {sent && <p className="sent-message">¡Mensaje enviado con éxito!</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Footer;
